import React, { Component, useState, useEffect, useRef } from "react";
import { getTrackers } from "./Services/HomeServices";
import DataTable from "react-jquery-datatables";
import { Table, Row, Col, Collapse } from "antd";
import steps from '../Assets/steps.png';

import { Spin } from "antd";
import $ from "jquery";
import "antd/dist/antd.css";
import { FieldsDisplay } from "./Constants";

const bootstrap = require("bootstrap");

const { Panel } = Collapse;
const HomeDisplay = () => {
    return (
        <div className="ml10">
            <img src={steps} className="steps" />

            <h3>Stages
            </h3>
            <p>
                Your bookings are divided into four stages as follows:

            </p>
            <ol>
                <li>
                    <strong>BOOKED</strong><br /> <span>Corresponds to vehicles/bookings that have been received but not yet planned.</span>
                    </li>
                <br/>
                <li>
                    <strong> PLANNED</strong><br />  <span>Corresponds to vehicles/bookings that have been planned but transport has not yet begun.</span>
                </li>
                <br />
                <li>
                    <strong> IN TRANSIT</strong><br />  <span>Corresponds to vehicles/bookings where transport service has begun including collections.</span>
                    </li>
                <br />
                <li>
                    <strong> DELIVERED</strong><br />  <span>Corresponds to completed delivery.</span>
                    </li>
                <br />
            </ol>
            
          
        </div>
    );
};
export default HomeDisplay;
