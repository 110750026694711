const apiProtocall = 'https';
//const apiUrl = 'https://cc-carwaystrackandtrace-dev.azurewebsites.net';
//const apiPort = '443'; 

//const apiUrl = 'https://localhost';
//const apiPort = '5001'; 


const apiTimeOut = 10000;

const constantparameters = {

    //environmentName: "",
    //environmentName: "DEV",
    //environmentName: "UAT",

    //local
    // apiUrlWithPort:`https://localhost:5001`,

    //dev
    // apiUrlWithPort: `https://cc-carwaystrackandtrace-dev.azurewebsites.net:443`,

    //uat
    // apiUrlWithPort:`https://cc-carwaystrackandtrace-uat.azurewebsites.net:443`,

    //prod
    apiUrlWithPort: `https://cc-carwaystrackandtrace-prod.azurewebsites.net:443`,
};

module.exports = constantparameters;