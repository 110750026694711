import React, { Component, useState, useEffect } from "react";
import Home from "./Home";
import { useHistory } from "react-router-dom";
import Guid from "guid";
import { customerValidate } from "./Services/HomeServices";

const Client = () => {
    const history = useHistory();

    const [clientguidStatus, setClientguidStatus] = useState(false);
    const [clientguid, setClientguid] = useState("");

    useEffect(() => {
        loadFunc();
    }, []);

    const loadFunc = async () => {

        const param = history.location.search.split("?");
        const validGuid = Guid.isGuid(param[1]);
        if (validGuid) {
            let guidData = {
                cus_guid: param[1],
            };
            const validation = await customerValidate(guidData);
            setClientguid(param[1]);
            if (validation.data) {
                setClientguidStatus(validation.data);
            }
        }
    }

    return (
        <div>
            <Home client={clientguidStatus} clientguid={clientguid} />
        </div>
    );
};
export default Client;
