import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import banner from '../Assets/banner.jpg';
import configConstants from "../config/constants";


export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
            <NavMenu /> 
            <img className="banner" src={banner} />
            <div className="titleblock">
                <Container>
                    <h2>{configConstants.environmentName} Carways Tracking Portal</h2>
                </Container>
            </div>
            <Container className="mcont">
                {this.props.children}

            </Container>
            {/*<Container>*/}
            {/*    <p className="pull-right caps">* conducted in accordance to below terms of service</p>*/}
            {/*</Container>*/}
            <div class="footer">
                <div class="textwidget">&copy; Copyright <a href="https://carways.com.au" rel="nofollow noopener" target="_blank">Carways</a>. All rights reserved. <a className="pull-right" href="https://www.carways.com.au/terms/">Terms of Service</a></div>
            </div>
      </div>
    );
  }
}
