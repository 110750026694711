import React, { Component } from 'react';
import { Route } from 'react-router';
import { BrowserRouter } from "react-router-dom";
import { Layout } from './components/Layout';
import Home from './components/Home';
import FetchData from './components/FetchData';
import FetchFromData from './components/FetchFromData';
import Client from './components/Client';

import { Counter } from './components/Counter';
import './custom.css'
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');


export default class App extends Component {
  static displayName = App.name;


  render() {
    return (
      <Layout>
        <BrowserRouter>
          <Route exact path='/' component={Home} />
          <Route exact path='/client' component={Client} />
          <Route exact path='/fetch' component={FetchData} />
          <Route exact path='/booking' component={FetchFromData} />
        </BrowserRouter>
      </Layout>
    );
  }
}
