import axios from "../../config/axiosInstance";
import configConstants from "../../config/constants";

export const getTrackers = async (dataParams) => {
    try {
        const apiUrl = configConstants.apiUrlWithPort;
        const dataBody = await axios.get(
            `${apiUrl}/api/tracker`,
            {
                params: dataParams
            }

        );
        return dataBody;
    } catch (err) {
        throw err;
    }
};


export const customerValidate = async (guid) => {
    try {
        const apiUrl = configConstants.apiUrlWithPort;
        const dataBody = await axios.get(
            `${apiUrl}/api/tracker/CustomerValidate`,
            {
                params: guid
            }
        );
        return dataBody;
    } catch (err) {
        throw err;
    }
}

export const getTrackersByGuid = async (dataParams) => {
    try {
        const apiUrl = configConstants.apiUrlWithPort;
        const dataBody = await axios.get(
            `${apiUrl}/api/tracker/GetDataByGuid/${dataParams.guid}`
        );
        return dataBody;
    } catch (err) {
        throw err;
    }
};

export const getMedia = async (dataParams) => {
    try {
        const apiUrl = configConstants.apiUrlWithPort;
        const dataBody = await axios.get(
            `${apiUrl}/api/Survey/${dataParams.guid}`
        );
        return dataBody;
    } catch (err) {
        throw err;
    }
};

