import React, { Component, useState, useEffect, useRef } from "react";
import { getTrackersByGuid, getMedia } from "./Services/HomeServices";
import DataTable from "react-jquery-datatables";
import { Table, Row, Col, Collapse } from "antd";
import { Container } from 'reactstrap';

import { Spin } from "antd";
import $ from "jquery";
import "antd/dist/antd.css";
import { FieldsDisplay } from "./Constants";
import HomeDisplay from "./HomeDefaultDisplay";
import Guid from "guid";
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
/*window.jQuery = $;
window.$ = $;
global.jQuery = $;*/
const bootstrap = require("bootstrap");
//import Popper from "popper.js";
//import bootstrap from "bootstrap";

// rowSelection object indicates the need for row selection
const { Panel } = Collapse;
const FetchFromData = ({ client, clientguid }) => {
    const [trackerList, setTrackerList] = useState([]);
    const [searchedList, setSearchedList] = useState([]);

    const [searchCrit, setSearchCrit] = useState("");
    const [searchCritValue, setSearchCritValue] = useState("");

    const [isSearch, setIsSearch] = useState(false);
    const [isFullSearch, setIsFullSearch] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isjobListSelected, setIsjobListSelected] = useState(false);

    const [jobTopicDetails, setJobTopicDetails] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [selectedJobListView, setSelectedJobListView] = useState([]);
    const [isSelectedItemView, setIsSelectedItemView] = useState(null);

    const [selectedJobTitle, setSelectedJobTitle] = useState("");
    const [indexOfSelectedJob, setIndexOfSelectedJob] = useState("");
    const [selectedFieldName, setFieldName] = useState("Vehicle ID");
    const [selectedJobHeaderFieldName, setJobHeaderFieldName] = useState("Job Number");

    const [searching, setSearching] = useState(false);

    const [page, setPage] = useState(1);
    const ref = useRef(null);
    const history = useHistory();
    useEffect(() => {
        initDataInPage();
    }, []);
    useEffect(() => { }, [trackerList]);
    useEffect(() => { onChangeCollapse('0'); }, [jobTopicDetails]);
    useEffect(() => {
    }, [client]);
    //  useEffect(() => { }, [selectedJobListView]);
    // useEffect(() => { }, [isSearch]);

    const [img, setImg] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const columns = [
        {
            title: "Booking Ref #",
            dataIndex: "booking_number",
            key: "name",
        },
        {
            title: "Order No",
            dataIndex: "order_number",
            key: "age",
        },
        {
            title: selectedFieldName,
            dataIndex: "vehicle_id",
            key: "address",
        },
        {
            title: "Status",
            dataIndex: "trip_status",
            key: "address",
        },
        //{
        //    title: "Stock No",
        //    dataIndex: "stock_no",
        //    key: "address",
        //},
        {
            title: "",
            dataIndex: "",
            key: "",
            render: (item, i, index) => (
                <>
                    <button
                        type="button"
                        class="btn btn-search wrn-btn wbtn"
                        onClick={() => onView(item, i, index)}
                    >
                        View
                    </button>
                </>
            ),
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            render: (item, i, index) => (
                <>
                    <button
                        type="button"
                        class="btn btn-search wrn-btn wbtn"
                        className={(item.is_exist_media && index === 0) ? null : "d-none"}
                        onClick={() => onViewDocument(item, i, index)}
                    >
                        View Documents
                    </button>
                </>
            ),
        }
    ];

    const onView = (item, i, index) => {
        setIsSelectedItemView(item);
    };

    const onViewDocument = async (item, i, index) => {
        setSearching(true);

        let surveyData = {
            guid: item.media_identifier
        };

        const mediaData = await getMedia(surveyData);
        var url = `data:${mediaData.data.contentType};base64,${mediaData.data.fileContents}`;

        var blobUrl;
        var fetchUrl = await fetch(url)
            .then(res => res.blob())
            .then(URL.createObjectURL)
            .then((ret) => { blobUrl = ret; return blobUrl; });

        setImg(fetchUrl);

        handleShow();
        setSearching(false);
    };

    const initDataInPage = async () => {
        //  jobTopicClick();
        loadFunc();
    };

    const SelectedItemView = ({ job_number }) => {
        return (
            <div id="details">
                {isSelectedItemView != undefined &&
                    isSelectedItemView &&
                    isSelectedItemView.job_number === job_number ? (
                    <>
                        <br />
                        <h5 class="bold">
                            Booking Details{" "}
                            <span className="pull-right">
                                ETA{" "}
                                <span className="black-box">
                                    {isSelectedItemView.est_deliver_date}
                                </span>
                            </span>
                        </h5>
                        <hr />
                        {/* <div className="row">
                                <div className="col-md-3">
                                    <h5>Booking Number</h5>
                                    <h4>{isSelectedItemView.booking_number}</h4>
                                </div>
                                <div className="col-md-3">
                                    <h5>Order Number</h5>
                                    <h4>{isSelectedItemView.order_number}</h4>
                                </div>
                                <div className="col-md-3">
                                    <h5>Vehicle Number</h5>
                                    <h4>{isSelectedItemView.vehicle_id}</h4>
                                </div>
                            </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Customer</h5>
                                <hr className="dotted" />
                            </div>
                            <div className="col-md-4">
                                <h4>{isSelectedItemView.cus_name}</h4>
                            </div>

                            <div className="col-md-5 pl">
                                <h4>{isSelectedItemView.vehicle_description}</h4>
                            </div>
                            <div className="col-md-3 right pl30">
                                <h4>{isSelectedItemView.trip_status}</h4>
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <div className="col-md-12">
                                <h5>Collection</h5>
                                <hr className="dotted" />
                            </div>
                            <div className="col-md-6">
                                <h4>{isSelectedItemView.collect_name}</h4>
                            </div>

                            <div className="col-md-3 pl">
                                <h4>{isSelectedItemView.collect_suburb}</h4>
                            </div>

                            <div className="col-md-3 pl30">
                                <h4>{isSelectedItemView.collect_state}</h4>
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <div className="col-md-12">
                                <h5>
                                    Delivery{" "}
                                    <span className="pull-right ">
                                        Date &nbsp;&nbsp;{" "}
                                        <span className="white-box sbold">
                                            {isSelectedItemView.act_deliver_date}
                                        </span>
                                    </span>
                                </h5>
                                <hr className="dotted" />
                            </div>
                            <div className="col-md-6">
                                <h4>{isSelectedItemView.deliver_name}</h4>
                            </div>

                            <div className="col-md-3 pl">
                                <h4>{isSelectedItemView.deliver_suburb}</h4>
                            </div>

                            <div className="col-md-3 pl30">
                                <h4>{isSelectedItemView.deliver_state}</h4>
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <div className="col-md-12">
                                <h5>Consignment</h5>
                                <hr className="dotted" />
                            </div>
                            <div className="col-md-6">
                                <h4>
                                    <span className="nobold">Note :</span>{" "}
                                    {isSelectedItemView.consignment_instructions}
                                </h4>
                            </div>
                        </div>
                        <Container>
                            <a className="pull-right caps" href="https://www.carways.com.au/terms/">* conducted in accordance to below terms of service</a>
                        </Container>
                    </>
                ) : null}
            </div>
        );
    };

    const resetPage = () => {
        setTrackerList([]);
        setSearchedList([]);
        /*   setSearchCrit("");
            setSearchCritValue("");*/
        setIsSearch(false);
        setIsFullSearch(false);
        setIsSelected(false);
        setIsjobListSelected(false);
        setJobTopicDetails([]);
        setSelectedJob([]);
        setSelectedJobListView([]);
        setIsSelectedItemView(null);
        setSelectedJobTitle("");
        setIndexOfSelectedJob("");
        // setSearching(false);
        setPage(1);

        return true;
    };

    const loadFunc = async () => {

        const param = history.location.search.split("?");
        const validGuid = Guid.isGuid(param[1].toString());

        if (validGuid) {

            await setTrackerDetailsByGuid(param[1]);
        }
    }

    const setTrackerDetailsByGuid = async (guid) => {
        setSearching(true);
        setIsjobListSelected(false);
        setSelectedJobTitle("");
        await setIsSelectedItemView(null);
        setJobTopicDetails([]);
        let updatedData = {
            guid: guid
        };
        const trackers = await getTrackersByGuid(updatedData);
        setTrackerList(trackers.data.responseData);
        let trackertopicsList = trackers.data.responseData;
        if (trackertopicsList.length > 0) {
            await setJobTopicDetails([trackertopicsList[0].job_number]);


            for (var i = 0; i < trackertopicsList.length - 1; i++) {
                if (
                    trackertopicsList[i].job_number !==
                    trackertopicsList[i + 1].job_number
                ) {
                    setJobTopicDetails((topics) => [
                        ...topics,
                        trackertopicsList[i + 1].job_number,
                    ]);
                }
            }
            setIsSearch(true);
        }
        if (trackertopicsList.length === 0) {
            setJobTopicDetails([]);
            setIsSearch(false);
            //setIsSelected(true);
            setSelectedJob([]);
        }
        setSearching(false);
    }

    const tableRowClass = (record) => {
        if (isSelectedItemView != null) {
            return record.id === isSelectedItemView.id
                ? "table-row-light"
                : "table-row-dark";
        }
    };

    const JobList = () => {
        // rowClassName={(record, index) => record.id === isSelectedItemView.id ? 'table-row-light' : 'table-row-dark'}
        /*        if (job_number === selectedJobTitle && isjobListSelected === true) {*/
        if (selectedJobListView.length > 0) {
            return (
                /*                <div className="panel-body show">*/
                <Table
                    pagination={{
                        defaultPageSize: 5,
                        onChange(current) {
                            setPage(current);
                        },
                        defaultCurrent: page,
                        showTotal: (total) => {
                            return `Showing ${total} entries`;
                        },
                    }}
                    dataSource={selectedJobListView}
                    columns={columns}
                    rowClassName={tableRowClass}
                />
                /*                </div>*/
            );
        } else {
            return null;
        }
        /*  }
                  else {
                      return (
                          <div className="panel-body hide">
                              <Table pagination={{
                                  defaultPageSize: 5, onChange(current) {
                                      setPage(current);
                                  }, defaultCurrent: page, showTotal: ((total) => {
                                      return `Showing ${total} entries`
                                  }),
                              }} dataSource={selectedJobListView} columns={columns} rowClassName={tableRowClass} />
                          </div>
                      );
                  }*/
    };

    const onChangeCollapse = async (items) => {
        const jobNumber = jobTopicDetails[items];
        await setPage(1);
        await setSelectedJobListView([]);
        setIsSelectedItemView(null);
        /*  if (selectedJobTitle === jobNumber && items != undefined) {
                setIsjobListSelected(!isjobListSelected);
            } else {
                setIsjobListSelected(true);
            }*/
        if (items != undefined) {
            const selectJobList =
                trackerList &&
                trackerList.length > 0 &&
                trackerList.filter((item) => {
                    if (item.job_number === jobNumber) {
                        return item;
                    }
                });
            setSelectedJobListView(selectJobList);
            setIsSelected(true);
            setSelectedJobTitle(jobNumber);
        } else {
            return null;
        }
    };

    return (
        <div>

            <Spin className="spin" tip="Loading..." spinning={searching}>
                <section className="hidden">
                    <div class="row">
                        <div class="col-md-12">
                            <br />
                            <br />
                            <div
                                class="alert alert-success"
                                role="alert"
                                hidden={isFullSearch ? false : true}
                            >
                                {jobTopicDetails.length} Results Found!
                            </div>

                            <div>
                                {/*<Collapse accordion>
                                        <Panel header="This is panel header 1" key="1">
                                            <p>T1</p>
                                        </Panel>
                                        <Panel header="This is panel header 2" key="2">
                                            <p>T2</p>
                                        </Panel>
                                        <Panel header="This is panel header 3" key="3">
                                            <p>T3</p>
                                        </Panel>
                                    </Collapse> :null*/}

                                {jobTopicDetails.length > 0 ? (
                                    <Collapse
                                        accordion
                                        id="collapse"
                                        //onChange={onChangeCollapse}
                                        ref={ref}
                                        activeKey={1}
                                    >
                                        {jobTopicDetails &&
                                            jobTopicDetails != undefined &&
                                            jobTopicDetails.length > 0 &&
                                            jobTopicDetails.map((item, i) => {
                                                return (
                                                    <Panel header={`${selectedJobHeaderFieldName}. ${item}`} key={i + 1}>
                                                        {selectedJobListView.length > 0 ? (
                                                            <Table
                                                                pagination={{
                                                                    defaultPageSize: 5,
                                                                    onChange(current) {
                                                                        setPage(current);
                                                                    },
                                                                    defaultCurrent: page,
                                                                    showTotal: (total) => {
                                                                        return `Showing ${total} entries`;
                                                                    },
                                                                }}
                                                                dataSource={selectedJobListView}
                                                                columns={columns}
                                                                rowClassName={tableRowClass}
                                                            />
                                                        ) : null}

                                                        <div>
                                                            {isSelectedItemView != null ? (
                                                                <SelectedItemView job_number={item} />
                                                            ) : null}
                                                        </div>
                                                    </Panel>
                                                );
                                            })}
                                    </Collapse>
                                ) : (null)}
                                <br />
                                <div
                                    class="col-md-12"
                                    hidden={
                                        isSearch && trackerList && trackerList.length === 0
                                            ? false
                                            : true
                                    }
                                >
                                    <br />
                                    <br />
                                    <h4>Job Details Not Available</h4>
                                </div>
                            </div>
                            {/* <div className="col-md-12">
                            <br />
                            <h4>Delivery Progress</h4>
                            <hr />
                            <div className="table-responsive">
                                <table class="table table-striped">
                                    <thead class="thead-dark">
                                        <tr height="18">
                                            <th height="18" width="25">
                                                L
                      </th>
                                            <th width="125">COLLECTED</th>
                                            <th width="125">TRANSIT</th>
                                            <th width="125">DELIVERED</th>
                                            <th width="125">ETA</th>
                                            <th width="84">COMPLETED</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr height="18">
                                            <td height="18" className="yellow">
                                                1
                      </td>
                                            <td className="yellow">waiting for update</td>
                                            <td className="yellow">waiting for update</td>
                                            <td className="yellow">waiting for update</td>
                                            <td className="yellow">eta date</td>
                                            <td className="yellow">N</td>
                                        </tr>
                                        <tr height="18">
                                            <td height="18" className="green">
                                                1
                      </td>
                                            <td className="green">YES</td>
                                            <td className="green">in transit</td>
                                            <td className="yellow">waiting for update</td>
                                            <td className="green">eta date</td>
                                            <td className="yellow">N</td>
                                        </tr>
                                        <tr height="18">
                                            <td height="18" className="green">
                                                1
                      </td>
                                            <td className="green">YES</td>
                                            <td className="green">finished</td>
                                            <td className="green">YES</td>
                                            <td className="green">eta date</td>
                                            <td className="green">Y</td>
                                        </tr>
                                        <tr height="18">
                                            <td height="18" className="green">
                                                1
                      </td>
                                            <td className="green">YES</td>
                                            <td className="green">finished</td>
                                            <td className="green">YES</td>
                                            <td className="green">eta date</td>
                                            <td className="green">Y</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>*/}

                            {/*<div className="col-md-12">
                            <br />
                            <h4>Survey Data </h4>
                            <hr />
                            <div className="table-responsive">
                                <table class="table table-striped">
                                    <thead class="thead-dark">
                                        <tr height="18">
                                            <th height="18" width="25">
                                                Brief
                      </th>
                                            <th width="125">Detailed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr height="18">
                                            <td height="18" className="yellow">
                                                waiting completed
                      </td>
                                            <td className="yellow">waiting for Brief available</td>
                                        </tr>
                                        <tr height="18">
                                            <td height="18" className="yellow">
                                                waiting completed
                      </td>
                                            <td className="yellow">waiting for Brief available</td>
                                        </tr>
                                        <tr height="18">
                                            <td height="18" className="green">
                                                available
                      </td>
                                            <td className="yellow">processing</td>
                                        </tr>
                                        <tr height="18">
                                            <td height="18" className="green">
                                                available
                      </td>
                                            <td className="green">available</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>*/}
                        </div>
                    </div>
                </section>
            </Spin>

            <Modal size="lg" className="modal-full-width" show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body><iframe src={img} width="100%" height="600" ></iframe></Modal.Body>
            </Modal>
        </div>
    );
};
export default FetchFromData;
